@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/gilroy/gilroy-extrabold-webfont.woff2') format('woff2'),
  url('../fonts/gilroy/gilroy-extrabold-webfont.woff') format('woff'),
  url('../fonts/gilroy/gilroy-extrabold-webfont.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/gilroy/gilroy-light-webfont.woff2') format('woff2'),
  url('../fonts/gilroy/gilroy-light-webfont.woff') format('woff'),
  url('../fonts/gilroy/gilroy-light-webfont.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato/lato-thin-webfont.woff2') format('woff2'),
  url('../fonts/lato/lato-thin-webfont.woff') format('woff'),
  url('../fonts/lato/lato-thin-webfont.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato/lato-light-webfont.woff2') format('woff2'),
  url('../fonts/lato/lato-light-webfont.woff') format('woff'),
  url('../fonts/lato/lato-light-webfont.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato/lato-semibold-webfont.woff2') format('woff2'),
  url('../fonts/lato/lato-semibold-webfont.woff') format('woff'),
  url('../fonts/lato/lato-semibold-webfont.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato/lato-black-webfont.woff2') format('woff2'),
  url('../fonts/lato/lato-black-webfont.woff') format('woff'),
  url('../fonts/lato/lato-black-webfont.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
// Variables
font-text= 'Lato', Helvetica, sans-serif
font-ttl= 'Gilroy', sans-serif
transition= all 0.2s ease-out

green= #0fa2b0
gray= #434a54

w1365= 'screen and (max-width: 1365px)'
w1200= 'screen and (max-width: 1200px)'
w991= 'screen and (max-width: 991px)'
w768= 'screen and (max-width: 767px)'
w480= 'screen and (max-width: 480px)'


// Fonts


* {
  box-sizing border-box
}

html {
  height 100%
  font-size 62.5%
  -webkit-font-smoothing antialiased
}

body {
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size 16px
  line-height 1.33
  font-weight normal
  color gray
  background-color #f8f8f8
}

b {
  font-weight 600 !important
}

a:focus {
  outline 0 !important
}

.wrapper {
  width 100%
  min-height 100%
  height 100%
}

.section {
  padding 40px 0
  position relative
  z-index 0
  @media w991 {
    padding 30px 0
  }
  &_green {
    background-color green
    color #fff
    .numbered-section {
      &__ttl {
        color #fff
      }
      &__info {
        &-number {
          color: rgba(255, 255, 255, 0.1);
          @media w768 {
            background-color rgba(67,74,84,0.3)
            color #fff
          }
        }
        &-ttl {
          &:before {
            background-color #fff
          }
        }
      }
    }
  }
  &_maingreen {
    background-color green
  }
  &__ttl {
    font-size 48px
    font-family font-ttl
    font-weight 100
    margin 0 0 60px 0
    @media w991 {
      font-size 36px
      margin-bottom 45px
    }
    @media w768 {
      font-size 32px
      margin-bottom 30px
    }
    &-text {
      display inline-block
      position relative
      padding-right 50px
      line-height 1
      &:before,&:after {
        content ''
        display block
        position absolute
        right 0
      }
      &:before {
        width calc(100% + 40px)
        height 7px
        background-color rgba(67,74,84,0.1);
        top 50%
        z-index: -1;
      }
      &:after {
        top 5px
        width 70px
        height 2px
        background-color gray
      }
    }
  }
  &__item {
    margin-bottom 120px
    &:last-child {
      margin-bottom 0
    }
    @media w768 {
      margin-bottom 80px
    }
    @media w480 {
      margin-bottom 60px
    }
  }
}

.container {
  max-width 1165px
  min-width 320px
  margin 0 auto
  position relative
  z-index 1
  @media w1200 {
    padding 0 20px
  }
}

.list {
  margin 0
  padding 0
  &__item {
    list-style none
  }
  &-default {
    li {
      list-style none
      position relative
      padding-left 25px
      margin-bottom 50px
      &:last-child {
        margin-bottom 0
      }
      @media w768 {
        margin-bottom 30px
      }
      @media w480 {
        margin-bottom 30px
      }
      &:before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        background-color: #0fa3b1;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 10px;
      }
    }
  }
}

.img {
  display block
  max-width 100%
}

.hidden {
  display none
}

.text-center {
  text-align center
}

.link {
  text-decoration none
  color inherit
}

.flex {
  display flex
}

// Buttons
.btn {
  display inline-block
  border 1px solid green
  background-color green
  height: 52px;
  line-height 1.2
  text-transform uppercase
  min-width 270px
  color #fff
  padding: 17px 30px 15px 30px
  text-decoration none
  text-align center
  cursor pointer
  transition transition
  font-family font-text
  font-weight 900
  font-size 16px
  border-radius: 10px;
  @media w480{
    font-size 12px
    height 40px
    padding: 11px 30px 9px 30px;
    min-width: 240px;
  }
  &:focus {
    outline 0
  }
  &:hover {
    background #fff
    border-color #fff
    color green
  }
  &_block {
    display block
    width 100%
  }
  &_white{
    background-color transparent
    border-color #fff
    &:hover {
      background-color #fff
      color green
    }
  }
  &_green{
    background-color transparent
    color green
    border-color green
    &:hover {
      background-color green
      color #fff
    }
  }
}

.tooltip {
  display inline-block
  position relative
  &:hover {
    .tooltip__content {
      opacity 1
      visibility: visible;
    }
  }
  &__link {
    text-decoration none
    display inline-block
    border-bottom 1px dashed green
    cursor pointer
  }
  &__content {
    position absolute
    top 30px
    left 50%
    transform translateX(-50%)
    background-color #fff
    font-size 10px
    padding 10px
    border-radius 10px
    width 170px
    opacity 0
    transition transition
    visibility: hidden;
    &-ttl {
      text-transform uppercase
      margin-bottom 10px
    }
    &:before {
      content ''
      display block
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 15px 15px 15px;
      border-color: transparent transparent #fff transparent;
      position absolute
      top -15px
      left 50%
      transform translateX(-50%)
    }
  }
}

.form {
  &__item {
    margin-bottom 30px
    position relative
    @media w480 {
      margin-bottom 20px
    }
    &-label {
      display block
      margin-bottom 15px
      @media w480 {
        margin-bottom 10px
      }
    }
    &-input {
      @media w991 {
        max-width 100%
      }
    }
    &-textarea {
      @media w991 {
        max-width 100%
      }
    }
  }
  &__agr {
    font-size 12px
  }
  &__error {
    font-size 12px
    color red
  }
  &__footer {
    display flex
    flex-wrap wrap
    align-items center
    @media w1200 {
      display block
    }
    @media w768 {
      .btn {
        width 100%
      }
    }
  }
  &__text-sending {
    text-transform: uppercase;
    font-weight: 700;
    margin-left: 24px;
    color: #fff;
    padding: 14px 30px;
    border-radius: 10px;
    background-color green
  }
}

// Input
.input,
.textarea,
.select  {
  display block
  width 100%
  outline none
  padding 10px
  height 50px
  border 1px solid transparent
  border-radius 10px
  background-color #fff
  transition transition
  font-family font-text
  font-size 16px
  transition transition
  @media w480 {
    height 40px
    padding 5px 10px
  }
  &:focus {
    border 1px solid gray
    background-color: #fff
    color #666
    outline none
  }
  &_error {
    background-color #fff
    border 1px solid red !important
  }
}

.textarea {
  resize: vertical;
  min-height: 150px;
}

.select {
  display block
  width 100%
  padding 10px
  border 1px solid #bbb
  &_small {
    height 30px
    padding 3px
  }
}

.table {
  width 100%
  border-collapse collapse
  border-spacing 0
  border 1px solid #eee
  font-size 14px
  text-align center
  th {
    text-transform uppercase
  }
  tr {
    border-top 1px solid #eee
    border-bottom 1px solid #eee
    &:hover {
      background-color #f4f4f4
    }
  }
  td,th {
    padding 8px
    border-right 1px solid #eee
    &:first-child {
      width 220px
    }
    &:last-child {
      border-right 0
    }
  }
}

.flex-justify {
  display flex
  flex-wrap wrap
  justify-content space-between
}

.list-default {
  .list__item {
    position relative
    padding-left 20px
    &:before {
      content ''
      display block
      width 5px
      height 5px
      border-radius 50%
      background-color #d81681
      position absolute
      left 0
      top 8px
    }
  }
}

// Styles
.main-slide {
  background #00a5b3 url('../img/bg-main.jpg') no-repeat top center
  @media w991 {
    background-image url('../img/bg-no-cup.jpg')
  }
  @media w768 {
    background green
  }
  &__box {
    padding-top 80px
    position relative
    @media w480 {
      padding-top 40px
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 100%;
      background: #fff
      height: 100%;
      width: 100%;
      @media w991 {
        content none
      }
    }
  }
  &__content {
    margin-top 135px
    padding-bottom 60px
    @media w768 {
      margin-top 100px
    }
    @media w480 {
      margin-top 50px
    }
  }
  .language-btns__item {
    color #fff
  }
  .language-btns__delimiter {
    background-color #fff
  }
}

.main-header {
  position relative
  z-index 2
  &_white {
    background-color #fff
  }
  &__box {
    display flex
    flex-wrap wrap
    justify-content space-between
    padding 30px 0
    letter-spacing: 1.2px;
    font-size 12px
    font-weight 600
    @media w991 {
      padding 20px 0
    }
  }
  &__item {
    margin auto 0
    &:nth-child(3){
      @media w991 {
        width 100%
        flex-basis 100%
      }
    }
  }
  &__mobile-btn {
    display none
    @media w991 {
      display block
    }
  }
}

.main-header-nav {
  display flex
  position relative
  z-index 1
  @media w991 {
    justify-content space-between
  }
  &__item {
    margin auto 0
    &:nth-child(1) {
      margin auto 35px 0 auto
      @media w991 {
        margin 0
      }
    }
  }
}

.logo-company {
  display flex
  @media w991 {
    max-width 140px
  }
  &__img {
    margin auto 0
  }
}

.phone {
  color inherit
  text-decoration none
  display flex
  transition transition
  &:hover {
    color #000
  }
  &__icon {
    width 15px
    height 15px
    fill gray
    margin auto 5px auto 0
  }
  &__text {
    margin auto 0
  }
}

.login {
  display none
  text-decoration none
  color inherit
  transition fill 0.1s ease-out
  &:hover .login__icon{
    fill #000
  }
  &__icon {
    fill gray
    width 24px
    height 24px
  }
}

.main-nav {
  display flex
  justify-content space-between
  width 450px
  transition: all .3s ease-out;
  @media w991 {
    position absolute
    right 0
    top 110px
    display block
    width 100%
    background-color gray
    z-index 2
    max-height: 0;
    overflow: hidden;
    &_active {
      max-height 150px
      overflow visible
    }
  }
  &__item {
    text-transform uppercase
    color inherit
    text-decoration none
    padding 2px 5px
    line-height 1
    @media w991 {
      display block
      color #fff
      padding 15px 10px
    }
    &:hover {
      text-decoration underline
    }
  }
}

.mobile-menu-btn {
  display block
  width 30px
  height 20px
  position relative
  cursor pointer
  &:before,
  &:after {
    content: '';
    width: 100%;
    height: 3px;
    background-color: gray;
    position: absolute;
    left: 0;
    transition: 0.3s ease-out;
  }
  &:before {
    top 0
  }
  &:after {
    bottom 0
  }
  &_active {
    &:before,
    &:after {
      top 50%
    }
    &:before {
      transform rotate(45deg) translateY(-50%)
    }
    &:after {
      transform rotate(-45deg) translateY(-50%)
      left 2px
    }
    .mobile-menu-btn__item {
      opacity 0
    }
  }
  &__item {
    height 3px
    width 100%
    background-color gray
    position absolute
    left 0
    top 50%
    transform translateY(-50%)
  }
}

.language-btns {
  display flex
  &__item {
    text-decoration none
    color inherit
    opacity 0.3
    transition opacity 0.1s ease-out
    &:hover {
      opacity 1
    }
    &_active {
      opacity 1
    }
  }
  &__delimiter {
    width 1px
    height 9px
    background-color gray
    margin auto 7px
    opacity 0.3
  }
}

.main-ttl {
  font-family font-ttl
  text-align center
  font-size 48px
  line-height 1.1
  margin 0
  position relative
  padding 0 40px
  @media w991 {
    font-size 36px
    padding 0 30px
  }
  @media w768 {
    font-size 32px
  }
  @media w480 {
    font-size 23px
    padding 0 12px
  }
  &:before,
  &:after {
    display block
    position absolute
    font-size 100px
    line-height 1
    top 50%
    transform translateY(-50%)
    @media w768 {
      font-size 80px
      color #fff
    }
  }
  &:before {
    content '['
    left -5px
    @media w480 {
      left -15px
    }
  }
  &:after {
    content ']'
    right -5px
    @media w480 {
      right -15px
    }
  }
  &__sub {
    display block
    font-size 30px
    font-weight 300
    @media w991 {
      font-size 22px
    }
  }
}

.main-content {
  display flex
  justify-content space-between
  flex-wrap wrap
  &__item {
    width 370px
    padding 55px 0 0 55px
    position relative
    @media w991 {
      padding 0
      text-align center
      width 48%
    }
    @media w768 {
      width 100%
      color #fff
      margin-bottom 40px
      &:last-child {
        margin-bottom 0 
      }
    }
    &:first-child {
      .main-content__item-ttl {
        color green
        @media w768 {
          color inherit
        }
      }
      &:before {
        color rgba(15, 162, 176, 0.2)
      }
    }
    &:last-child {
      .main-content__item-ttl {
        color #fff
      }
      &:before {
        color rgba(255, 255, 255, 0.1)
      }
    }
    &:before {
      content '?'
      font-family font-ttl
      font-weight 800
      line-height 0.75
      font-size 400px
      display block
      position absolute
      left 0
      top 0
      z-index: -1;
      @media w991 {
        content none
      }
    }
    &-ttl {
      font-family font-ttl
      font-weight 800
      line-height: 1.25;
      font-size 36px
      margin 0 0 45px 0
      @media w991 {
        font-size 26px
      }
      @media w480 {
        font-size 22px
        margin-bottom 20px
      }
    }
    &-text {
      min-height 142px
      margin-bottom 35px
      @media w768 {
        min-height inherit
        margin-bottom 20px
      }
      p {
        margin 0 0 25px 0
        @media w480 {
          margin-bottom 10px  
        }
        &:last-child {
          margin-bottom 0
        }
      }
    }
  }
  .btn {
    @media w768 {
      border-color: #fff;
      color #fff
    }
  }
}

.number-block {
  margin-bottom 60px
  &__ttl {
    text-transform uppercase
    color green
    margin-bottom 40px
  }
  &__list {
    counter-reset: section;
    &-item {
      position relative
      margin-bottom 40px
      padding-left 15px
      @media w768 {
        padding-left: 45px;
      }
      &:last-child {
        margin-bottom 0
      }
      &:before {
        counter-increment: section;
        content: counter(section);
        display block
        font-size 60px
        font-weight 900
        line-height: 0.33;
        position absolute
        left 0
        top 50%
        transform translateY(-50%)
        color rgba(67, 74, 84, 0.2)
      }
    }
  }
}

.next-block {
  &__ttl {
    font-weight bold
    margin-bottom 30px
  }
  &__item {
    display flex
    margin-bottom 20px
    &:last-child {
      margin-bottom 0
    }
    &-img {
      width 65px
      height 65px
      margin auto 25px auto 0
    }
    &-text {
      margin auto 0
    }
  }
}

.main-footer {
  padding 20px 0
  font-size 12px
}

.numbered-section {
  position relative
  padding-right 200px
  @media w768 {
    padding-right 0
  }
  &__ttl {
    text-transform uppercase
    color green
    line-height: 1.2;
    letter-spacing: 1.6px;
    margin-bottom 50px
    @media w768 {
      font-size 14px
      margin-bottom 30px
    }
  }
  &__text {
    margin-bottom 50px
  }
  &__info {
    position absolute
    top 20px
    right 0
    width 160px
    height 240px
    @media w768 {
      display flex
      position relative
      top inherit
      right inherit
      width inherit
      height inherit
      margin-bottom 15px
      font-weight 900
    }
    &-number {
      font-size 300px
      font-family font-ttl
      line-height 0.8
      font-weight 800
      color rgba(67, 74, 84, 0.1);
      text-align center
      @media w768 {
        font-size 21px
        width 24px
        height 24px
        background-color green
        line-height 24px
        text-align left
        color #fff
        text-align center
      }
    }
    &-ttl {
      text-transform uppercase
      padding-left 10px
      transform rotate(-90deg)
      white-space: nowrap;
      transform-origin: 100% 0;
      position absolute
      top 0
      right 70px
      @media w768 {
        transform-origin inherit
        position relative
        top inherit
        transform inherit
        right inherit
        line-height: 24px;
      }
      &:before {
        content ''
        display block
        width 56px
        height 2px
        background-color gray
        position absolute
        right 100%
        top 50%
        transform translateY(-50%)
        @media w768 {
          content none
        }
      }
    }
  }
}

.arrows-block {
  &__item {
    width 240px
    position relative
    @media w991 {
      width 100% !important
      display flex
      align-items center
      margin-bottom: 20px;
      &:last-child {
        margin-bottom 0
      }
    }
    &:last-child:after {
      display none
    }
    &:after {
      content ''
      display block
      width 9px
      height 17px
      background url('../img/arrow.svg') no-repeat center
      background-size cover
      position absolute
      right -10px
      top 70px
      @media w1200 {
        content none
      }
    }
    &-link {
      display flex
      text-decoration none
      width 155px
      height 155px
      flex-shrink 0
      background-color #fff
      border-radius 50%
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
      margin-bottom 35px
      @media w991 {
        width 65px
        height 65px
        margin 0 20px 0 0
      }
    }
    &-img {
      margin auto
      width 65px
      height 65px
      @media w991 {
        width 40px
        height 40px
      }
    }
    &-text {
      line-height 1.2
    }
  }
  &_4b {
    .arrows-block__item {
      width 160px
    }
    .arrows-block__item:after {
      right -55px
      background-image url(../img/arrow-white.svg)
    }
  }
}

.task-block {
  &__ttl {
    font-family font-ttl
    font-size 30px
    letter-spacing: 3px;
    color gray
    font-weight 800
    padding-left 25px
    border-left 3px solid
    margin 60px 0
    @media w768 {
      margin 30px 0
      font-size 22px
      padding-left 15px
    }
  }
  &__desc {
    border 1px solid rgba(67,74,84,0.1)
    padding 25px
    @media w768 {
      padding 15px
    }
  }
}

.circle-list {
  padding-top 40px;
  @media w991 {
    padding 0 0 60px 0
  }
  @media w768 {
    padding 0
  }
  &__row {
    display flex
    flex-wrap wrap
    margin-bottom 100px
    @media w991 {
      margin-bottom 0
    }
    &:nth-child(2n) {
      .circle-list__item {
        @media screen and (min-width: 1200px) {
          margin 0 0 0 140px
        }
      }
    }
  }
  &__item {
    position relative
    padding-left 80px
    margin 0 160px 0 0
    @media w1200 {
      margin 0 30px 0 0
    }
    @media w991 {
      width 100%
      padding-left 30px
      margin 0 0 15px 0
    }
    &-icon {
      width 98px
      height 98px
      position absolute
      left 0
      top 50%
      transform translateY(-50%)
      @media w991 {
        width 10px
        height 10px
        top 47%
      }
    }
    &-text {
      position relative
      color gray
      z-index 1
      &-pick {
        color #fff
      }
    }
  }
}

#circle{
  .st0{
    fill:none;
    stroke: #fff;
    stroke-miterlimit:10;
    stroke-dasharray:3.0002,3.0002;
    @media w991 {
      fill #fff
    }
  }
  .st1{
    fill: rgba(255,255,255,0.2);
  }
  .st2 {
    fill #fff
  }
}

.tasks-table {
  margin-right -20px
  &__item {
    width 83px
    height 83px
    background-color #fff
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
    display flex
    margin  0 10px 10px 0
    cursor pointer
    &.hidden {
      display none
    }
    @media w768 {
      width 40px
      height 40px
    }
    &:hover {
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    }
    &-text {
      margin auto
    }
  }
}

.tasks-filter {
  margin-bottom 40px
  &__item {
    margin-bottom 20px
    &:last-child {
      margin-bottom 0
    }
    &-ttl {
      text-transform uppercase
      font-weight bold
      margin-bottom 10px  
    }
  }
  &__list {
    display flex
    flex-wrap wrap
    &-item {
      &-label {
        display block
        padding 5px 10px
        background-color #e7e8e9
        margin 5px 5px 0 0
        cursor pointer
        font-size 14px
        &.active {
          background-color green
          color #fff
        }
      }
      &-input {
        display none
      }
    }
  }
}

.text-send {
    margin-left 20px
    display: flex;
    height: 52px;
    border-radius: 10px;
    font-family: font-ttl
    font-weight: 900;
    font-size: 16px;
    border-radius: 10px;
    text-transform: uppercase;
    overflow hidden
    @media w768 {
      display block
      text-align center
      height inherit
    }
    @media w480 {
      font-size 12px
    }
    @media w1200 {
      margin 20px 0 0 0
    }
    &__item {
      opacity 0
      transition transition
      visibility hidden
      &:first-child {
        background-color green
        color #fff
        padding: 17px 30px 15px 30px;
      }
      a {
        display block
        color inherit
        text-decoration none
        background-color #e7e8e9
        padding: 17px 30px 15px 30px;
        &:hover {
          background-color #dedede
        }
      }
    }
}

.form.active {
  .text-send__item {
    opacity 1
    visibility visible
  }
}

@import normalize